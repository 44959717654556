<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">Hisob faktura ruyhati</div>
        </div>
        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="getData"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :loading="isLoading"
            no-data-text="Ma'lumotlar topilmadi"
          >
            <template v-slot:[`item.payment_type_name`]="{ item }">
              <div class="payment__name" :class="item.payment_text_color">
                <span
                  :class="[item.payment_color_label]"
                  class="label label-dot"
                ></span>
                {{ item.payment_type_name }}
              </div>
            </template>
            <template v-slot:[`item.zayavka_status_name`]="{ item }">
              <div :class="item.status_style" class="status__name">
                {{ item.zayavka_status_name }}
              </div>
            </template>
            <!-- date -->
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | formatDate }}
            </template>
            <template v-slot:[`item.direction_cities`]="{ item }">
              <span
                v-for="(dest, index) in item.direction_cities"
                :key="dest.id"
              >
                {{ dest.city_name.name }}
                <span v-if="index == item.direction_cities.length - 1"></span>
                <span v-else>-</span>
              </span>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      currentPage: 1,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Mijoz', value: 'zayavka_owner' },
        { text: 'Shartnoma', value: 'contract' },
        { text: 'Postavshik', value: 'deliver' },
        { text: 'Narxi', value: 'price' },
        { text: 'Nds', value: 'nds_summa' },
        { text: 'Narxi(nds)', value: 'price_with_nds' },
        { text: 'Sana', value: 'created_at' },
        { text: 'Yonalish', value: 'direction_cities' },
        { text: 'Yetib borgan', value: 'delivered_date' },
        { text: 'Status', value: 'zayavka_status_name' }
      ]
    }
  },
  created() {
    this.$store.dispatch('getClientPageFacturesDetails', this.$route.params.id)
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Hisob faktura ruyhati' }])
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getData() {
      const data = this.$store.state.clientStore.clientPageFacturesDetails
      if (data.length !== 0) {
        data.forEach((element) => {
          if (element.nds_summa !== null) {
            element.nds_summa = element.nds_summa.toLocaleString('es-US')
          }
          if (element.price !== null) {
            element.price = element.price.toLocaleString('es-US')
          }
          if (element.price_with_nds !== null) {
            element.price_with_nds =
              element.price_with_nds.toLocaleString('es-US')
          }
          if (element.created_at !== null) {
            element.created_at = element.created_at.slice(0, 10)
          }
          if (element.delivered_date !== null) {
            element.delivered_date = element.delivered_date.slice(0, 10)
          }

          if (element.payment_type == 'CONT') {
            element.payment_type_name = "Pul ko'chirish"
            element.payment_text_color = 'text-primary'
            element.payment_color_label = 'label-primary'
          }
          if (element.payment_type == 'NAQD') {
            element.payment_type_name = "Naqd to'lov"
            element.payment_text_color = 'text-danger'
            element.payment_color_label = 'label-danger'
          }
          if (element.zayavka_status == 'OTW') {
            element.status_style = 'warning'
            element.zayavka_status_name = "Yo'lda"
          }
          if (element.zayavka_status == 'DEL') {
            element.status_style = 'success'
            element.zayavka_status_name = 'Yetkazilgan'
          }
        })
      }
      return data
    }
  },
  methods: {
    aa() {}
  }
}
</script>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
